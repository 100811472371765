import React, { CSSProperties } from 'react';

import { useEnvironment, useTranslation } from '@wix/yoshi-flow-editor';
import {
  Challenge,
  DurationUnit,
} from '@wix/ambassador-challenges-v1-challenge/types';
import { useSettings } from '@wix/tpa-settings/react';
import { dataHooks } from '@wix/data-hooks';

import { getDurationForFlexyTimelines } from '../../../../../selectors/challenges';
import utils from '../../views/utils';

import { classes, st } from './ChallengeStats.st.css';
import { useCSSPBStyle } from '../../../../../hooks/useCSSPBStyle';

export interface IChallengeStatsProps {
  className?: string;
  challenge: Challenge;
}

export const challengeStatsHooks = dataHooks<{
  main;
  duration;
  steps;
  participants;
}>('challenge-stats');

const renderDetailsItem = (
  description: string,
  dataHook: string,
  style?: CSSProperties,
) => {
  return (
    <li className={classes.detailsListItem} data-hook={dataHook} style={style}>
      <span className={classes.detailsListItemDescription}>{description}</span>
    </li>
  );
};

export const ChallengeStats: React.FunctionComponent<IChallengeStatsProps> = ({
  className = '',
  challenge,
}: IChallengeStatsProps) => {
  const { t } = useTranslation();
  const { isMobile, isRTL } = useEnvironment();
  const settings = useSettings();
  const {
    showDuration,
    showParticipants,
    showSteps,
    overviewItemsCount,
    stepsCount,
  } = utils.getBaseViewOptionsForSidebar(challenge, settings);
  const getCSSPBStyle = useCSSPBStyle();
  const duration = getDurationForFlexyTimelines(challenge);

  return (
    <ul
      style={{
        gridTemplateColumns: `repeat(${overviewItemsCount}, 135px)`,
      }}
      className={`${st(classes.root, {
        mobile: isMobile,
        align: utils.getHeaderAlignByType(settings, isRTL),
      })} ${className}`}
      data-hook={challengeStatsHooks.main()}
      dir="auto"
    >
      {showDuration
        ? renderDetailsItem(
            duration?.unit === DurationUnit.DAYS
              ? t(`challenge.page.details.days_icu`, { count: duration?.value })
              : t(`challenge.page.details.weeks_icu`, {
                  count: duration?.value,
                }),
            challengeStatsHooks.duration(),
            getCSSPBStyle({
              displayVar: '--opgSidebarLayoutDisplayChallengeDuration',
            }),
          )
        : null}

      {showSteps
        ? renderDetailsItem(
            t(`challenge.page.details.steps_icu`, { count: stepsCount }),
            challengeStatsHooks.steps(),
            getCSSPBStyle({
              displayVar: '--opgSidebarLayoutDisplayChallengeSteps',
            }),
          )
        : null}

      {showParticipants
        ? renderDetailsItem(
            t(`challenge.page.details.participants_icu`, {
              count: challenge.participantsSummary.participantsNumber,
            }),
            challengeStatsHooks.participants(),
            getCSSPBStyle({
              displayVar: '--opgSidebarLayoutDisplayChallengeParticipants',
            }),
          )
        : null}
    </ul>
  );
};
ChallengeStats.displayName = 'ChallengeStats';

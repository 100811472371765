import React from 'react';

import { StepsListAsTiles } from '../StepsListAsTiles';

import { useSettings } from '@wix/tpa-settings/react';
import challengeSettings from '../../../settingsParams';
import {
  useBi,
  useEnvironment,
  useExperiments,
  useTranslation,
} from '@wix/yoshi-flow-editor';

import { SectionsAsTilesAccordion } from '../../../../../components-shared/SectionsAsTilesAccordion';

import { classes } from './SectionsListAsTiles.st.css';
import { isStepResolved } from '../../views/utils';
import { useSections } from '../../../../../contexts/ParticipantSections/ParticipantSectionsContext';
import { Challenges } from '../../../../../editor/types/Experiments';
import {
  getSectionTitle,
  isSectionLocked,
  sectionCannotBeOpenedInView,
} from '../../../../../selectors/sections';
import { ParticipantStep } from '@wix/ambassador-challenges-v1-participant/types';
import LockedIcon from '../../../../../assets/icons/locked-fill.svg';
import { useMonitoring } from '../../../../../contexts/Monitoring/MonitoringContext';
import { FedopsInteraction } from '../../../../../config/fedopsInteraction';
import { useUser } from '../../../../../contexts/User/UserContext';
import { memberWebAppButtonClick } from '@wix/bi-logger-challenges-member-web/v2';
import { TextButton, TextButtonPriority } from 'wix-ui-tpa/cssVars';

export interface ISectionsListAsTilesProps {
  dataHook?: string;
  dataHookForStepsList?: string;
  currentStepId: string;
  onSectionChosen?: Function;
  chosenSectionTileId?: string;
  onStepChosen(step: ParticipantStep): void;
}

export const SectionsListAsTiles: React.FunctionComponent<
  ISectionsListAsTilesProps
> = (props: ISectionsListAsTilesProps) => {
  const { onSectionChosen, chosenSectionTileId } = props;
  const bi = useBi();
  const settings = useSettings();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile } = useEnvironment();
  const {
    isListParticipantSectionsRequestInProgress,
    listParticipantSections = [],
  } = useSections();
  const { startInteraction } = useMonitoring();
  const { isParticipantInSuspendedState, isParticipantInLockedState } =
    useUser();

  if (isListParticipantSectionsRequestInProgress) {
    // TODO: add spinner;
    return null;
  }

  return (
    <div data-hook={props.dataHook || null}>
      <ul className={classes.sectionsList}>
        {listParticipantSections.map((section, ind: number, arr) => {
          const steps = section.steps;
          const key = `section-${section.id}`;
          const sectionSteps = steps || [];
          const resolvedLength = sectionSteps.filter((step) =>
            isStepResolved(step),
          ).length;
          const isOpenSection = !!steps.find(
            (step) => step?.id === props.currentStepId,
          );

          function viewSectionDetails() {
            if (onSectionChosen) {
              startInteraction(
                FedopsInteraction.ViewSectionAtSidebarLayout,
                section.id,
              );
              bi.report(
                memberWebAppButtonClick({
                  buttonName: 'openSectionInView',
                  sectionId: section.id,
                }),
              );
              onSectionChosen(section.id);
            }
          }

          const isActiveSection = chosenSectionTileId === section.id;
          const isHideSection =
            experiments.enabled(Challenges.enableDripContent) &&
            isSectionLocked(section);

          const subtitle = (
            <>
              {isHideSection ? <LockedIcon /> : null}
              {isParticipantInSuspendedState || isParticipantInLockedState
                ? t('live.challenges-page.section-steps-subtitle-short', {
                    total: sectionSteps.length,
                  })
                : t('live.challenges-page.section-steps-subtitle', {
                    resolved: resolvedLength,
                    total: sectionSteps.length,
                  })}
            </>
          );

          return (
            <li key={`section-${ind}`} className={classes.sectionsListItem}>
              <SectionsAsTilesAccordion
                id={key}
                key={key}
                lastItem={arr.length - 1 === ind}
                className={isActiveSection ? classes.sectionActive : ''}
                data-hook={
                  props.dataHook ? `${props.dataHook}__accordion-button` : null
                }
                opened={isOpenSection || false}
                title={getSectionTitle(section.source)}
                subtitle={subtitle}
                emptyChildren={!!steps.length}
                align={settings.get(
                  challengeSettings.sidebarLayoutTextAlignment,
                )}
                onClick={() => {
                  bi.report(
                    memberWebAppButtonClick({
                      buttonName: 'toggleSectionDropdown',
                      sectionId: section.id,
                    }),
                  );
                  if (!isMobile && !sectionCannotBeOpenedInView(section)) {
                    viewSectionDetails();
                  }
                }}
              >
                {sectionCannotBeOpenedInView(section) || !isMobile ? null : (
                  <TextButton
                    className={classes.viewSectionDetails}
                    priority={TextButtonPriority.primary}
                    onClick={viewSectionDetails}
                  >
                    {t('challenge-card.view-button')}
                  </TextButton>
                )}
                {!isHideSection && (
                  <StepsListAsTiles
                    dataHook={props.dataHookForStepsList}
                    isSection={true}
                    currentStepId={props.currentStepId}
                    steps={sectionSteps}
                    isSPC={true}
                    onStepChosen={props.onStepChosen}
                  />
                )}
              </SectionsAsTilesAccordion>
            </li>
          );
        })}
      </ul>
    </div>
  );
};
SectionsListAsTiles.displayName = 'SectionsListAsTiles';
